import { isObjectLike } from 'lodash'
import { isValid as isEmailValid } from './email'
import { isValid as isUrlValid } from './url'

const validation = (options, value) => {
    const {
        required,
        read_only,
        max_length,
        max_value,
        min_value,
        max_digits,
        decimal_places,
        type,
        regex,
    } = options

    // 0 or false are valid values, null, undefined or empty string not
    const isValue = value !== undefined && value !== null && value !== ''

    if (required && !isValue) {
        // console.warn(options.label, 'is required')
        return 'field is required'
    }

    if (read_only && isValue) {
        console.warn(options.label, 'is readonly')
        return 'field is read only'
    }

    // test value
    if (isValue) {
        if (regex) {
            const valid = regex.test(value)
            if (!valid) {
                return 'does not match regex'
            }
        }

        if (type === 'nested_object' && !isObjectLike(value)) {
            console.warn(options.label, 'is not object')
            return 'expect a object'
        }

        if (type === 'url') {
            const valid = isUrlValid(value)
            if (!valid) {
                console.warn(options.label, 'is not url')
                return 'not a valid url'
            }
        }

        if (type === 'email') {
            const valid = isEmailValid(value)
            if (!valid) {
                console.warn(options.label, 'is not email')
                return 'not a valid email address'
            }
        }

        if (type === 'confirm' && value !== true) {
            console.warn(options.label, 'is not confirmed')
            return 'not confirmed'
        }

        if (type === 'boolean' && !(value === true || value === false)) {
            console.warn(options.label, 'is not boolean')
            return 'not boolean'
        }

        if ((type === 'number' || type === 'float') && !isFinite(value)) {
            console.warn(options.label, 'is not a number')
            return 'not a number'
        }

        if (max_length && value.length > max_length) {
            console.warn(options.label, 'exceeds max length')
            return 'exceeds max length'
        }

        if (max_value && value > max_value) {
            console.warn(options.label, 'exceeds max value')
            return 'exceeds max value'
        }

        if (min_value && value < min_value) {
            console.warn(options.label, 'exceeds min value')
            return 'exceeds min value'
        }

        if (max_digits && `${value}`.replace('.', '').length > max_digits) {
            console.warn(options.label, 'exceeds max digets')
            return 'exceeds max digets'
        }

        if (decimal_places) {
            let decimals = 0
            // check if it is a decimal
            if (value % 1 !== 0) {
                // TODO remove if we found the error
                if (!`${value}`.split('.')[1]) {
                    console.warn('no decimals found for', { value })
                }

                decimals = `${value}`.split('.')[1].length
            }
            if (decimals > decimal_places) {
                console.warn(options.label, 'exceeds decimal places')
                return 'exceeds decimal places'
            }
        }
    }

    return
}

export default validation
