import { call } from '@zupr/api'

interface FormApiProps {
    url: string
    values?: Record<string, any>
    params?: Record<string, any>
    variables?: Record<string, any>
    body?: Record<string, any>
}

const formApi = ({ url, values, params, variables, ...rest }: FormApiProps) => {
    const body = rest.body || values || params || variables
    return {
        delete: async () => {
            try {
                return await call({ url, body, method: 'DELETE' })
            } catch (response) {
                // log
                const clone = response.clone()
                const error = await clone.json()
                console.error({ error })
                throw error
            }
        },
        post: async () => {
            try {
                return await call({ url, body, method: 'POST' })
            } catch (response) {
                // log
                const clone = response.clone()
                const error = await clone.json()
                console.error({ error })
                throw error
            }
        },
        patch: async () => {
            try {
                return await call({ url, body, method: 'PATCH' })
            } catch (response) {
                // log
                const clone = response.clone()
                const error = await clone.json()
                console.error({ error })
                throw error
            }
        },
    }
}

export default formApi
